import React from 'react'
import ic_guide from 'assets/imgs/ic_guide.png'
import styles from './FaqLink.module.css'

export default function FaqLink({ data, title, link }) {
  let language = localStorage.getItem('locale')
  const popTitle = language === 'ko' ? data['Pop Title ko'] : data['Pop Title']

  return (
    <div>
      <a target='_blank' rel='noopener noreferrer' href={link} className={styles.faqlink_container}>
        <img className={styles.faqlink_img} src={ic_guide} alt={popTitle} />
        <p className={styles.faqlink_title}>{title}</p>
      </a>
    </div>
  )
}
