import React, { Component } from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

class ColoredCircularProgress extends Component {
  render() {
    const theme = createMuiTheme({
      palette: {
        secondary: {
          main: '#cdd3d8',
        },
      },
    })

    return (
      <ThemeProvider theme={theme}>
        <CircularProgress color='secondary' />
      </ThemeProvider>
    )
  }
}

export default ColoredCircularProgress
