import { Container } from 'unstated'

class PartsTypeContainer extends Container {
  state = {
    partsType: 'In-Wall Mount',
  }

  setPartsType(partsType) {
    this.setState({
      ...this.state,
      partsType: partsType,
    })
  }
}

export default PartsTypeContainer
