import React from 'react'
import ic_back from 'assets/imgs/ic_back.png'
import styles from './IntroToolbar.module.css'
import { FormattedMessage } from 'react-intl'

class IntroToolbar extends React.Component {
  render() {
    const cell = (
      <div className={styles.intro_toolbar}>
        <div className={styles.intro_toolbar_header}>
          <img
            className={styles.intro_toolbar_back}
            alt='ic_back'
            src={ic_back}
            onClick={this.props.handleClickBack}></img>

          <div className={styles.intro_toolbar_header_title}>{this.props.title}</div>
        </div>
        {this.props.type === 'producttype' ? (
          <div className={styles.intro_toolbar_text}>
            <FormattedMessage id='choose_your_product_types' />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )

    return cell
  }
}

export default IntroToolbar
