import React from 'react'
import styles from './MountSet.module.css'
import { NavLink } from 'react-router-dom'
import IntroToolbar from 'component/IntroToolbar/IntroToolbar'
import ProductPackage from 'component/ProductPackage/ProductPackage'
import NeedBoxList from 'component/NeedBoxList/NeedBoxList'
import needBoxListData from 'component/NeedBoxList/NeedBoxListData'
import AzureSolidButton from 'component/AzureSolidButton/AzureSolidButton'
import { injectIntl } from 'react-intl'

class MountSet extends React.Component {
  handleClickBack = () => {
    this.props.history.goBack()
  }
  productPackage = (type) => {
    return this.props.googleData ? (
      <ProductPackage
        type='set'
        img={type}
        title='Product Package'
        googleData={this.props.googleData['IntroPages']['elements']}
      />
    ) : null
  }
  render() {
    const partsType = this.props.match.params.type || 'In-Wall Mount'
    const needBoxList = needBoxListData.filter((item) => item.type === partsType)
    const { intl } = this.props
    return (
      // <Subscribe to={[PartsTypeContainer]}>
      //   {(partsTypeContainer) => {
      <div className={styles.mountset_container}>
        <IntroToolbar type='mountset' title={partsType + ' Set'} handleClickBack={this.handleClickBack} />
        {this.productPackage(partsType)}
        <NeedBoxList needBoxList={needBoxList} />
        <NavLink exact to={'/page/' + partsType + '/1' + window.location.search}>
          <AzureSolidButton text={intl.formatMessage({ id: 'start' })} />
        </NavLink>
      </div>
      // }}
      // </Subscribe>
    )
  }
}

export default injectIntl(MountSet)
