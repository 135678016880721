import React from 'react'

import styles from './ProductTypeBox.module.css'

const ProductTypeBox = (props) => {
  return (
    <div className={styles.product_type_box}>
      <div className={styles.product_type_box_title}>{props.title}</div>
      <div className={styles.product_type_box_text}>{props.text}</div>
      {props.img}
    </div>
  )
}

export default ProductTypeBox
