import React from 'react'
import styles from './IntroTitle.module.css'
import LogoDialog from 'component/LogoDialog/LogoDialog'

const IntroTitle = ({ title = '', text = '' }) => {
  return (
    <div>
      <LogoDialog />
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default IntroTitle
