import React from 'react'
import styles from './ProductTypeBoxList.module.css'
import ProductTypeBox from 'component/ProductTypeBox/ProductTypeBox'
import { NavLink } from 'react-router-dom'
import { Subscribe } from 'unstated'
import PartsTypeContainer from 'container/PartsTypeContainer'
import Skeleton from '@material-ui/lab/Skeleton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

class ProductTypeBoxList extends React.Component {
  makeImgs(data) {
    if (data == null) {
      return
    } else {
      const placeholder = <Skeleton variant='rect' width='100%' height='5.5rem' />
      return data.map((item) => (
        <LazyLoadImage
          className={styles.product_type_box_image}
          alt='Product Package'
          src={item['Image']}
          effect='opacity'
          placeholder={placeholder}
        />
      ))
    }
  }
  render() {
    const type = this.props.type || 'WiFi Network'
    const imgs = this.makeImgs(this.props.googleData.filter((item) => item['Figma'] === '1'))
    const arrayList = [
      {
        type: 'WiFi Network',
        partsType: 'In-Wall Mount',
        img: imgs[1],
        title: 'In-Wall Mount Set',
        text: 'Awair Omni + Surface Mount + In-Wall Mount',
        to: '/mountset/In-Wall Mount',
      },
      {
        type: 'WiFi Network',
        partsType: 'Surface Mount',
        img: imgs[2],
        title: 'Surface Mount Set',
        text: 'Awair Omni + Surface Mount',
        to: '/mountset/Surface Mount',
      },
      {
        type: 'WiFi Network',
        partsType: 'Secure Mount',
        img: imgs[3],
        title: 'Secure Mount Set',
        text: 'Awair Omni + Surface Mount + Gang Plate & Cover',
        to: '/mountset/Secure Mount',
      },
      {
        type: 'WiFi Network',
        partsType: 'Basic Mount',
        img: imgs[0],
        title: 'Basic Mount Set',
        text: 'Awair Omni',
        to: '/page/Basic Mount/1',
      },

      {
        type: 'BACnet',
        partsType: 'BACnet MS/TP Mount',
        img: imgs[1],
        title: 'BACnet MS/TP Mount Set',
        text: 'Awair Omni + Surface Mount + In-Wall Mount',
        to: '/mountset/BACnet Mount',
      },
      {
        type: 'BACnet IP',
        partsType: 'BACnet IP Mount',
        img: imgs[1],
        title: 'BACnet IP Mount Set',
        text: 'Awair Omni + Surface Mount + In-Wall Mount',
        to: '/mountset/BACnet IP Mount',
      },
      {
        type: 'Ethernet Network',
        partsType: 'Ethernet Mount',
        img: imgs[1],
        title: 'Ethernet Mount Set',
        text: 'Awair Omni + Surface Mount + In-Wall Mount',
        to: '/mountset/Ethernet Mount',
      },
    ].filter((item) => item['type'] === type)

    return (
      <Subscribe to={[PartsTypeContainer]}>
        {(partsTypeContainer) => (
          <div className={styles.product_type_box_list}>
            {arrayList.map((item) => {
              return (
                <NavLink
                  key={item.partsType}
                  exact
                  to={item.to + window.location.search}
                  onClick={(e) => {
                    partsTypeContainer.setPartsType(item.partsType)
                  }}>
                  <ProductTypeBox img={item.img} title={item.title} text={item.text} />
                </NavLink>
              )
            })}
          </div>
        )}
      </Subscribe>
    )
  }
}

export default ProductTypeBoxList
