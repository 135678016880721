import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './MountComplete.module.css'
import img_appdownload from 'assets/imgs/img_appdownload.png'
import ic_return from 'assets/imgs/ic_return.png'
import IntroTitle from 'component/IntroTitle/IntroTitle'
import ic_alert from 'assets/imgs/ic_alert.png'
import ic_next from 'assets/imgs/ic_next.png'
import AppDownDialog from 'component/AppDownDialog/AppDownDialog'
import { getOS } from 'lib/functions'

class MountComplete extends React.Component {
  makeAppDownButton() {
    const OS = getOS(window.navigator.userAgent)
    const button = (
      <div className={styles.complete_down_button}>
        <div className={styles.complete_down_text}>Download Mobile App</div>
        <img className={styles.complete_down_next} alt='Download Mobile App' src={ic_next} />
      </div>
    )
    if (OS === 'Android') {
      return <a href='https://play.google.com/store/apps/details?id=co.awair.b2b'>{button}</a>
    } else if (OS === 'iOS') {
      return <a href='https://apps.apple.com/us/app/awair-business/id1495610433'>{button}</a>
    } else {
      return <AppDownDialog type='mount' />
    }
  }

  render() {
    return (
      <div className={styles.complete_body}>
        <div className={styles.complete_container}>
          <IntroTitle
            title='Complete Mount Installation!'
            text='You’ve finished installing the mounts. Please download Awair Business app to proceed with onboarding Omnis
            to your Dashboard.'
          />

          <div className={styles.complete_down_container}>
            <img className={styles.complete_down_image} alt='Download Mobile App' src={img_appdownload} />
            {this.makeAppDownButton()}
            <div className={styles.complete_info_container}>
              <img className={styles.complete_info_img} alt='complete_info' src={ic_alert}></img>
              <div className={styles.complete_info}>
                To use the Mobile App, you need an Awair account that you used to sign in to the Dashboard.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.complete_footer}>
          <NavLink className={styles.complete_back_home} exact to='/intro'>
            <div className={styles.complete_back_home_text}>Back to Home</div>
            <img className={styles.complete_back_home_back} alt='Back to Home' src={ic_return}></img>{' '}
          </NavLink>
          <div className='flex_1'></div>
          <div className={styles.complete_copyright}>Copyright © 2020 Awair. All right reserved.</div>
        </div>
        <div></div>
      </div>
    )
  }
}

export default MountComplete
