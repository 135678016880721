import React, { Component } from 'react'
import { Dialog, Slide, TransitionProps } from '@material-ui/core'

import Toolbar from 'component/Toolbar/Toolbar'
import Parts from 'page/Parts/Parts'

import Footer from 'component/Footer/Footer'
import Menu from 'page/Menu/Menu'

import Card from 'component/Card/Card'
import ChoiceCard from 'component/ChoiceCard/ChoiceCard'
import styles from './Page.module.css'

const TransitionDown = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />
})

const TransitionUp = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})
class Page extends Component {
  constructor(props) {
    super(props)
    this.state = {
      next: '',
      top: false,
      bottom: false,
    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.onNextSubmit = this.onNextSubmit.bind(this)
  }

  toggleDrawer = (anchor, open) => (event) => {
    // if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return
    // }
    this.setState({ [anchor]: open })
    this.setState({ next: '' })
  }
  makeStep(data, maxStep) {
    if (data == null) return
    let step = data['Step']
    const items = []
    for (let index = 0; index < maxStep; index++) {
      if (step > index) {
        items.push(<div key={index} className={styles.step_item_on}></div>)
      } else {
        items.push(<div key={index} className={styles.step_item}></div>)
      }
    }
    return (
      <div className={styles.step_container} style={{ gridTemplateColumns: 'repeat(' + maxStep + ', 1fr)' }}>
        {items}
      </div>
    )
  }
  makeToolbar() {
    let anchor = 'top'
    return (
      <Toolbar
        handleClickBack={this.handleClickBack}
        googleData={this.props.googleData}
        toggleDrawer={this.toggleDrawer}>
        <Dialog
          anchor={anchor}
          open={this.state[anchor]}
          onClose={this.toggleDrawer(anchor, false)}
          TransitionComponent={TransitionUp}
          fullScreen
          keepMounted>
          {this.parts(anchor)}
        </Dialog>
      </Toolbar>
    )
  }
  makeFooter(data, next) {
    let anchor = 'bottom'
    return (
      <Footer next={next} data={data} googleData={this.props.googleData} toggleDrawer={this.toggleDrawer}>
        <Dialog
          anchor={anchor}
          open={this.state[anchor]}
          onClose={this.toggleDrawer(anchor, false)}
          TransitionComponent={TransitionUp}
          fullScreen
          keepMounted>
          <Menu {...this.props} anchor={anchor} toggleDrawer={this.toggleDrawer} googleData={this.props.googleData} />
        </Dialog>
      </Footer>
    )
  }
  makeCardAndFooter(data) {
    if (data == null) return
    if (data['Card Type'] === 'choice') {
      return (
        <div className={styles.content}>
          <ChoiceCard onChoice={this.onNextSubmit} next={this.state.next} data={data} />
          {this.makeFooter(data, this.state.next)}
        </div>
      )
    } else if (data['Card Type'] === 'card') {
      return (
        <div className={styles.content}>
          <Card data={data} />
          {this.makeFooter(data, data['Next'])}
        </div>
      )
    } else {
      return
    }
  }

  onNextSubmit(page) {
    this.setState({
      next: page,
    })
  }

  handleClickBack = () => {
    this.props.history.goBack()
  }

  parts = (anchor) => {
    return this.props.match.params ? (
      <Parts
        {...this.props}
        anchor={anchor}
        open={this.state[anchor]}
        toggleDrawer={this.toggleDrawer}
        googleData={this.props.googleData}
      />
    ) : null
  }
  render() {
    if (this.props.googleData === undefined) {
      return <div className={styles.main}>Loading</div>
    }

    const page = this.props.match.params.page || '0'
    const type = this.props.match.params.type
    let pageIndex = null
    let data = null
    let maxStep = null
    if (this.props.googleData) {
      let elements = this.props.googleData[process.env.REACT_APP_SHEET]['elements']
      pageIndex = elements.findIndex((item) => item['SKU'] === type && item['Index'] === page)
      data = elements[pageIndex]
      maxStep = elements[elements.findIndex((item) => item['SKU'] === type && item['Next'] === 'exit')]['Step']
    }

    return (
      <div className={styles.main}>
        {this.makeToolbar()}
        {this.makeStep(data, maxStep)}
        {this.makeCardAndFooter(data)}
      </div>
    )
  }
}

export default Page
