import React, { Component } from 'react'
import styles from './Card.module.css'
import GuideDialog from 'component/GuideDialog/GuideDialog'
import ColoredCircularProgress from 'component/ColoredCircularProgress/ColoredCircularProgress'
import FaqLink from 'component/FaqLink/FaqLink'

class Card extends Component {
  makeGuide(data) {
    if (data == null || data['Pop Title'] === '') return

    return <GuideDialog data={data} />
  }
  makeFaqLink(language) {
    if (
      this.props.data['Index'] === '1-b' ||
      this.props.data['Index'] === '2-b-b-6' ||
      this.props.data['Index'] === '2-b-a-4' ||
      this.props.data['Index'] === '3-c'
    ) {
      const title = language === 'ko' ? 'BACnet IP 수정 방법.' : 'How to edit BACnet IP configuration settings.'
      const link = 'https://support.getawair.com/hc/en-us/articles/360061540873'
      return <FaqLink data={this.props.data} title={title} link={link} />
    }
  }

  render() {
    const guide = this.makeGuide(this.props.data)
    const img = this.props.data['Image']
    let language = localStorage.getItem('locale')
    const faqLink = this.makeFaqLink(language)
    const title = language === 'ko' ? this.props.data['Title ko'] : this.props.data['Title']
    const description = language === 'ko' ? this.props.data['Description ko'] : this.props.data['Description']

    return (
      <div className={styles.card}>
        <p className={styles.card_title}>{title}</p>
        <p className={styles.card_desc}>{description}</p>
        <div className={styles.card_img_container}>
          <div className={styles.card_progress_container}>
            <ColoredCircularProgress />
          </div>
          <div
            className={styles.card_img}
            style={{
              backgroundImage: `url("${img}")`,
            }}
            alt={title}
          />
        </div>
        {faqLink}
        {guide}
      </div>
    )
  }
}

export default Card
