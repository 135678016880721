import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styles from './AppDownDialog.module.css'
import ic_next from 'assets/imgs/ic_next.png'
import { FormattedMessage } from 'react-intl'

export default function AppDownDialog({ type }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const makeAppDownButton =
    type === 'intro' ? (
      <div className={styles.intro_link_button}>
        <p className={styles.intro_link_button_text}>
          <FormattedMessage id='app_download' />
        </p>
      </div>
    ) : (
      <div className={styles.complete_down_button}>
        <div className={styles.complete_down_text}>
          <FormattedMessage id='app_download' />
        </div>
        <img className={styles.complete_down_next} alt='Download Mobile App' src={ic_next} />
      </div>
    )

  return (
    <div>
      <div className={styles.guide_container} onClick={handleClickOpen}>
        {/* <div className={styles.intro_link_button}>
          <p className={styles.intro_link_button_text}>App Download</p>
        </div> */}
        {makeAppDownButton}
      </div>
      <Dialog open={open} onClose={handleClose} className={styles.guide_pop_container}>
        <div className={styles.guide_pop_content}>
          <p className={styles.guide_pop_title}>
            <FormattedMessage id='download_awair_business_app' />
          </p>
          <p className={styles.guide_pop_desc}>
            <FormattedMessage id='download_awair_business_app_desc' />
          </p>
          <div></div>
        </div>
        <div>
          <a href='https://apps.apple.com/us/app/awair-business/id1495610433'>
            <p className={styles.guide_pop_close} onClick={handleClose}>
              <FormattedMessage id='apple_app_store' />
            </p>
          </a>
          <a href='https://play.google.com/store/apps/details?id=co.awair.b2b'>
            <p className={styles.guide_pop_close} onClick={handleClose}>
              <FormattedMessage id='google_play_store' />
            </p>
          </a>
          <p className={styles.guide_pop_close} onClick={handleClose}>
            <FormattedMessage id='close' />
          </p>
        </div>
      </Dialog>
    </div>
  )
}
