import React from 'react'

import IntroToolbar from 'component/IntroToolbar/IntroToolbar'
import ProductTypeBoxList from 'component/ProductTypeBoxList/ProductTypeBoxList'
import styles from './ProductType.module.css'

class ProductType extends React.Component {
  handleClickBack = () => {
    this.props.history.goBack()
  }

  render() {
    const type = this.props.match.params.type || 'WiFi Network'
    return (
      <div className={styles.productType_container}>
        <IntroToolbar type='producttype' title={type} handleClickBack={this.handleClickBack} />
        {this.props.googleData ? (
          <div className={styles.productTypeBoxList_container}>
            <ProductTypeBoxList googleData={this.props.googleData['IntroPages']['elements']} type={type} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default ProductType
