import React from 'react'
import IntroTitle from 'component/IntroTitle/IntroTitle'
import NetworkTypeBox from './NetworkTypeBox'
import styles from './NetworkType.module.css'
import ic_wifi from 'assets/imgs/ic_wifi.png'
import ic_bacnet from 'assets/imgs/ic_bacnet.png'
import ic_bacnetip from 'assets/imgs/ic_bacnetip.png'
import ic_doe from 'assets/imgs/ic_doe.png'
import NetworkTypeDialog from 'component/NetworkTypeDialog/NetworkTypeDialog'
import NetworkTypeBoxDialog from 'component/NetworkTypeBoxDialog/NetworkTypeBoxDialog'
import { injectIntl } from 'react-intl'

class NetworkType extends React.Component {
  render() {
    const { intl } = this.props
    return (
      <div className={styles.networktype_container}>
        <div className={styles.networktype_main}>
          <IntroTitle
            title={intl.formatMessage({ id: 'installation_guide' })}
            text={intl.formatMessage({ id: 'please_select_your_network_type_to_install_omni' })}
          />
          <NetworkTypeDialog />
          <NetworkTypeBox imgSrc={ic_wifi} text='WiFi Network' link='/producttype/WiFi Network' />
          <NetworkTypeBoxDialog />
          <NetworkTypeBox imgSrc={ic_doe} text='Ethernet Network' link='/producttype/Ethernet Network' />
          <NetworkTypeBox imgSrc={ic_bacnet} text='BACnet MS/TP<br/>(RS-485)' link='/producttype/BACnet' />
          <NetworkTypeBox imgSrc={ic_bacnetip} text='BACnet over IP<br/>(Ethernet)' link='/producttype/BACnet IP' />
          {/* <NetworkTypeBox imgSrc={ic_lora} text='LoRa Network' link='' /> */}
        </div>
        <div className={styles.copyright_text}>Copyright © 2020 Awair. All right reserved.</div>
      </div>
    )
  }
}

export default injectIntl(NetworkType)
