import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import { IntlProvider } from 'react-intl'
import * as serviceWorker from './serviceWorker'
import App from './App'
import en from 'locale/en.json'
import ko from 'locale/ko.json'

const messages = { en: en, ko: ko, }

let params = new URLSearchParams(window.location.search)
let language = 'en'

if (params.get('locale') === null) {
  let browserLang = window.navigator.userLanguage || window.navigator.language
  language = browserLang.includes('ko') ? 'ko' : 'en'
} else {
  language = params.get('locale') === 'ko' ? 'ko' : 'en'
}

localStorage.setItem('locale', language)

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
