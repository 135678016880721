import React, { Component } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { Provider } from 'unstated'
import Papa from 'papaparse'

import './shared/global.scss'
import Page from './page/Page'
import Intro from './page/Intro/Intro'
import NetworkType from './page/NetworkType/NetworkType'
import ProductType from './page/ProductType/ProductType'
import MountSet from './page/MountSet/MountSet'
import MountComplete from './page/MountComplete/MountComplete'
import GAListener from './lib/GAListener'

const stgTemplateGrid = '20803817'
const prdTemplateGrid = '1341634205'
const partsGrid = '1872884709'
const introGrid = '1113211628'

const promisePapaParse = (grid) => {
  const url = `https://docs.google.com/spreadsheets/d/e/2PACX-1vQMtueenuoAZIDAXeRPlkkeKpJILWOFtDSbeoqH9iCTlV9Snfm3OAwZeI-Mmb8oXLKvBI-RAnm-NO_o/pub?gid=${grid}&single=true&output=csv`
  return new Promise((resolve, reject) => {
    Papa.parse(url, {
      download: true,
      header: true,
      complete: (results: ParseResult) => {
        return resolve(results.data)
      },
      error: (error: ParseError) => {
        return reject(error)
      },
    })
  })
}

class App extends Component {
  state = {
    googleData: null,
  }

  componentDidMount() {
    Promise.all([
      promisePapaParse(introGrid),
      promisePapaParse(partsGrid),
      promisePapaParse(stgTemplateGrid),
      promisePapaParse(prdTemplateGrid),
    ])
      .then((values) => {
        this.setState({
          googleData: {
            ['IntroPages']: { elements: values[0] },
            ['Parts']: { elements: values[1] },
            ['STG_Template']: { elements: values[2] },
            ['PRD_Template']: { elements: values[3] },
          },
        })
      })
      .catch((err) => {
        console.log('Oops, something went wrong', err)
      })
  }

  render() {
    console.log('googleData = ', this.state.googleData)
    return (
      <div id='App' className='App'>
        {/* <Main /> */}
        {/* <Intro /> */}
        {/* <NetworkType /> */}
        {/* <ProductType /> */}
        {/* <MountSet /> */}
        {/* <Parts /> */}
        {/* <MountComplete /> */}

        <Provider>
          <BrowserRouter>
            <GAListener trackingId={process.env.REACT_APP_GA_CODE}>
              <Switch>
                <Route exact path='/' component={Intro} />
                <Route exact path='/intro' component={Intro} />
                <Route exact path='/page/:type/exit' component={MountComplete} />
                <Route path='/networktype' component={NetworkType} />
                <Route
                  path='/producttype/:type'
                  render={(props) => <ProductType {...props} googleData={this.state.googleData} />}
                />
                <Route
                  path='/mountset/:type'
                  render={(props) => <MountSet {...props} googleData={this.state.googleData} />}
                />
                <Route
                  path='/page/:type/:page'
                  render={(props) => <Page {...props} googleData={this.state.googleData} />}
                />
                {/* <Route path='/parts' component={Parts} /> */}
                <Route path='/mountcomplete' component={MountComplete} />
                {/* <Route path='/' render={(props) => this.showGuide(props)} /> */}
                {/* <Route path={`${'/' + this.state.index}`} render={(props) => this.showGuide(props)} /> */}
              </Switch>
            </GAListener>
          </BrowserRouter>
        </Provider>
      </div>
    )
  }
}

export default App
