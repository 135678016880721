import React from 'react'
import ColoredCircularProgress from 'component/ColoredCircularProgress/ColoredCircularProgress'
import Dialog from '@material-ui/core/Dialog'
import ic_guide from 'assets/imgs/ic_guide.png'
import styles from './GuideDialog.module.css'

export default function GuideDialog({ data }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  let language = localStorage.getItem('locale')
  const popTitle = language === 'ko' ? data['Pop Title ko'] : data['Pop Title']
  const popDescription = language === 'ko' ? data['Pop Description ko'] : data['Pop Description']

  return (
    <div>
      <div className={styles.guide_container} onClick={handleClickOpen}>
        <img className={styles.guide_img} src={ic_guide} alt={popTitle} />
        <p className={styles.guide_title}>{popTitle}</p>
      </div>
      <Dialog open={open} onClose={handleClose} className={styles.guide_pop_container}>
        <div className={styles.guide_pop_content}>
          <div className={data['Pop Image'] === '' ? styles.guide_pop_img_empty : styles.guide_pop_img_container}>
            <div className={styles.guide_pop_progress}>
              <ColoredCircularProgress className={styles.pop_progress} />
            </div>
            <img className={styles.guide_pop_img} src={data['Pop Image']} alt='' />
          </div>
          <p className={styles.guide_pop_title}>{popTitle}</p>
          <p className={styles.guide_pop_desc}>{popDescription}</p>
          <div></div>
        </div>
        <div>
          <p className={styles.guide_pop_close} onClick={handleClose}>
            Close
          </p>
        </div>
      </Dialog>
    </div>
  )
}
