import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styles from './NetworkTypeDialog.module.css'
import ic_guide from 'assets/imgs/ic_guide.png'
import { FormattedMessage } from 'react-intl'

export default function NetworkTypeDialog() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div className={styles.dialog_container} onClick={handleClickOpen}>
        <img className={styles.dialog_guide} alt='guide' src={ic_guide}></img>
        <p className={styles.dialog_title}>
          <FormattedMessage id='how_to_determine_my_network_type' />
        </p>
      </div>
      <Dialog open={open} onClose={handleClose} className={styles.dialog_pop_container}>
        <div className={styles.dialog_pop_content}>
          <p className={styles.dialog_pop_title}>
            <FormattedMessage id='how_to_determine_my_network_type' />
          </p>
          <p className={styles.dialog_pop_desc}>
            <FormattedMessage id='network_type_desc_1' />
          </p>
          <p className={styles.dialog_pop_desc}>
            <FormattedMessage id='network_type_desc_2' />
          </p>
          <div></div>
        </div>
        <div>
          <p className={styles.dialog_pop_close} onClick={handleClose}>
            <FormattedMessage id='close' />
          </p>
        </div>
      </Dialog>
    </div>
  )
}
