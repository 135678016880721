import React, { Component } from 'react'

import styles from './Toolbar.module.css'
import ic_back from 'assets/imgs/ic_back.png'
import LogoDialog from 'component/LogoDialog/LogoDialog'
import { FormattedMessage } from 'react-intl'

class Toolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  leftButton = () => {
    if (this.props.leftIcon === undefined) {
      return (
        <img className={styles.head_toolbar_back} src={ic_back} onClick={this.props.handleClickBack} alt='Back'></img>
      )
    } else {
      return (
        <img
          className={styles.head_toolbar_back}
          src={require(`assets/imgs/${this.props.leftIcon}.png`)}
          onClick={this.props.handleClickBack}
          alt='Back'></img>
      )
    }
  }
  title = () => {
    if (this.props.title === undefined) {
      return <LogoDialog />
    } else {
      return <span className={styles.head_toolbar_title}>{this.props.title}</span>
    }
  }
  rightButton = () => {
    let anchor = 'top'
    if (this.props.rightIcon === undefined) {
      return (
        <div className={styles.head_toolbar_menu} onClick={this.props.toggleDrawer(anchor, true)}>
          <FormattedMessage id='parts' />
        </div>
        // <NavLink
        //   exact
        //   to={{
        //     pathname: '/parts',
        //     state: {
        //       partsType: 'In-Wall Mount',
        //     },
        //   }}>
        //   <p id='toolbar_menu' className={styles.head_toolbar_menu}>
        //     Parts
        //   </p>
        // </NavLink>
      )
    } else if (this.props.rightIcon === '') {
      return <div className={styles.head_toolbar_menu}></div>
    }
  }
  render() {
    return (
      <div className={styles.head_container}>
        {this.props.children}
        <span className={styles.head_toolbar}>
          {this.leftButton()}
          {this.title()}
          {this.rightButton()}
        </span>
      </div>
    )
  }
}

export default Toolbar
