import React from 'react'
import styles from './ProductPackage.module.css'
import Skeleton from '@material-ui/lab/Skeleton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

class ProductPackage extends React.Component {
  makeImg(data) {
    if (data == null) {
      return
    } else {
      const placeholder = <Skeleton variant='rect' className={styles.product_img} />
      return (
        <LazyLoadImage
          className={styles.product_img}
          alt='Product Package'
          src={data['Image']}
          effect='opacity'
          placeholder={placeholder}
        />
      )
    }
  }

  render() {
    const cell = (
      <div>
        {this.props.type === 'set' ? (
          <div className={styles.set_title}>{this.props.title}</div>
        ) : (
          <div className={styles.parts_title}>{this.props.title}</div>
        )}
      </div>
    )
    let img = null
    if (this.props.googleData) {
      const typeIndex = this.props.googleData.findIndex(
        (item) => item['Section'] === this.props.img && item['Figma'] === '3'
      )
      img = this.makeImg(this.props.googleData[typeIndex])
    }

    return (
      <div className={styles.product_package}>
        {cell}
        {img}
      </div>
    )
  }
}

export default ProductPackage
