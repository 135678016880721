import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Footer.module.css'
import ic_list from 'assets/imgs/ic_list.png'
import ic_next from 'assets/imgs/ic_next.png'
import { FormattedMessage } from 'react-intl'

class Footer extends React.Component {
  onNext = (e, next) => {
    const type = this.props.data['Card Type']
    const choice1Next = this.props.data['Choice1 Next']
    const choice2Next = this.props.data['Choice2 Next']
    if (type === 'choice' && next !== choice1Next && next !== choice2Next) {
      e.preventDefault()
    }
  }

  makeFooter(next) {
    return (
      <NavLink
        className={next === '' ? styles.footer_next_button : styles.footer_next_button_on}
        onClick={(e) => this.onNext(e, next)}
        exact
        to={next + window.location.search}>
        <div className={styles.footer_next_button_text}>
          <FormattedMessage id='next' />
        </div>
        <img className={styles.footer_next_button_next} alt='NEXT' src={ic_next} />
      </NavLink>
    )
  }

  renderButtons() {
    const anchor = 'bottom'
    let { next } = this.props
    if (next.includes('exit')) {
      next = '/mountcomplete'
    }
    return (
      <div className={styles.footer_background}>
        {this.props.children}
        <div
          className={styles.footer_list}
          role='presentation'
          onClick={this.props.toggleDrawer(anchor, true)}
          onKeyDown={this.props.toggleDrawer(anchor, true)}>
          <img className={styles.footer_list_img} alt='list' src={ic_list}></img>
        </div>
        {/* <NavLink className={styles.footer_next_btn} onClick={(e) => this.onNext(e, next)} exact to={next}>
          NEXT
        </NavLink> */}
        {this.makeFooter(next)}
      </div>
    )
  }

  render() {
    const buttons = this.renderButtons()
    return <div className={styles.footer}>{buttons}</div>
  }
}

export default Footer
