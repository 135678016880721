import React, { Component } from 'react'
import classNames from 'classnames/bind'
import styles from './ChoiceCard.module.css'
import Skeleton from '@material-ui/lab/Skeleton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/opacity.css'

let cx = classNames.bind(styles)
class ChoiceCard extends Component {
  render() {
    let { next } = this.props
    let language = localStorage.getItem('locale')
    const title = language === 'ko' ? this.props.data['Title ko'] : this.props.data['Title']
    const description = language === 'ko' ? this.props.data['Description ko'] : this.props.data['Description']
    const choice1Name = language === 'ko' ? this.props.data['Choice1 Name ko'] : this.props.data['Choice1 Name']
    const choice2Name = language === 'ko' ? this.props.data['Choice2 Name ko'] : this.props.data['Choice2 Name']
    const choice1Next = this.props.data['Choice1 Next']
    const choice2Next = this.props.data['Choice2 Next']
    const placeholder = <Skeleton variant='rect' width='100%' height='10rem' />

    if (next !== '' && choice1Next !== next && choice2Next !== next) {
      this.props.onChoice('')
    }

    return (
      <div className={styles.choice}>
        <p className={styles.choice_title}>{title}</p>
        <p className={styles.choice_desc}>{description}</p>
        <div className={styles.choice_container}>
          <div
            className={cx({
              choice_card: true,
              choice_card_on: next === choice1Next,
            })}
            onClick={(e) => {
              this.props.onChoice(choice1Next)
            }}>
            <LazyLoadImage
              className={styles.choice_img}
              alt={choice1Name}
              src={this.props.data['Choice1 Image']}
              effect='opacity'
              placeholder={placeholder}
            />
            <div className={styles.choice_name_wrapper}>
              <p className={styles.choice_name}>{choice1Name}</p>
            </div>
          </div>
          <div
            className={cx({
              choice_card: true,
              choice_card_on: next === choice2Next,
            })}
            onClick={(e) => {
              this.props.onChoice(choice2Next)
            }}>
            <LazyLoadImage
              className={styles.choice_img}
              alt={choice2Name}
              src={this.props.data['Choice2 Image']}
              placeholder={placeholder}
            />
            <div className={styles.choice_name_wrapper}>
              <p className={styles.choice_name}>{choice2Name}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChoiceCard
