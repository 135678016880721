import React from 'react'
import styles from './PartsBoxList.module.css'
import PartsBox from 'component/PartsBox/PartsBox'

class PartsBoxList extends React.Component {
  render() {
    const boxList = this.props.partsBoxList
      ? this.props.partsBoxList.map((partsBox) => (
          <PartsBox
            key={partsBox['View Type'] + partsBox['Title']}
            type={partsBox['View Type']}
            title={partsBox['Title']}
            img={partsBox['Image']}
          />
        ))
      : null
    return <div className={styles.parts_box}>{boxList}</div>
  }
}

export default PartsBoxList
