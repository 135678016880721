import React from 'react'
import styles from './AzureSolidButton.module.css'

const AzureSolidButton = ({ text = '' }) => {
  return (
    <div className='side_padding_1_25'>
      <div className={styles.azure_solid_button}>{text}</div>
    </div>
  )
}

export default AzureSolidButton
