import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './IntroImage.module.css'
import ic_alert from 'assets/imgs/ic_alert.png'
import ic_next from 'assets/imgs/ic_next.png'

const IntroImage = ({ img = '', btn = '', link = '', info = '' }) => {
  const cell = (
    <div>
      {info.length > 0 ? (
        <div className={styles.intro_info_container}>
          <img className={styles.intro_info_img} alt='intro_info' src={ic_alert}></img>
          <div className={styles.intro_info}>{info}</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
  return (
    <div className={styles.intro_image_container}>
      <img className={styles.intro_image} alt={btn} src={img} />
      <NavLink className={styles.intro_button} exact to={link + window.location.search}>
        <div className={styles.intro_button_text}>{btn}</div>
        <img className={styles.intro_button_next} alt={btn} src={ic_next} />
      </NavLink>
      {cell}
    </div>
  )
}

export default IntroImage
