import React from 'react'
import styles from './NetworkTypeBox.module.css'
import { NavLink } from 'react-router-dom'
import ic_next_black from 'assets/imgs/ic_next_black.png'

const NetworkTypeBox = ({ imgSrc = '', text = '', link = '', nav }) => {
  return (
    <NavLink className={styles.networktype_box} exact to={link + window.location.search}>
      <div className={styles.networktype_box_item}>
        <img className={styles.networktype_box_item_img} alt={text} src={imgSrc} />
        <div className={styles.networktype_box_text} dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <img className={styles.networktype_box_next} alt='next' src={ic_next_black} />
    </NavLink>
  )
}

export default NetworkTypeBox
