import React from 'react'
import styles from './PartsBox.module.css'

class PartsBox extends React.Component {
  render() {
    let boxItem
    if (this.props.type === 'title') {
      boxItem = (
        <div className={styles.parts_type}>
          <div className={styles.parts_type_img_container}>
            <img className={styles.parts_type_img} alt={this.props.img} src={this.props.img} />
          </div>
          <div className={styles.parts_type_text}>{this.props.title}</div>
        </div>
      )
    } else {
      boxItem = (
        <div className={styles.parts_item}>
          <div className={styles.parts_item_container}>
            <img className={styles.parts_item_img} alt={this.props.img} src={this.props.img} />
          </div>
          <div className={styles.parts_item_text_container}>
            <div className={styles.parts_item_text}>{this.props.title}</div>
          </div>
        </div>
      )
    }

    return boxItem
  }
}

export default PartsBox
