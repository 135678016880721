import React from 'react'
import styles from './NeedBox.module.css'

const NeedBox = ({ img = '', title = '', desc = '' }) => {
  const cell = (
    <div>
      {desc.length > 0 ? (
        <div className={styles.need_box_text_container}>
          <div className={styles.need_box_title}>{title}</div>
          <div className={styles.need_box_desc}>{desc}</div>
        </div>
      ) : (
        <div className={styles.need_box_title}>{title}</div>
      )}
    </div>
  )

  return (
    <div className={styles.need_box}>
      <img className={styles.need_box_icon} alt={title} src={img} />
      {cell}
    </div>
  )
}

export default NeedBox
