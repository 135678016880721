import React from 'react'
// import { Subscribe } from 'unstated'

import ProductPackage from 'component/ProductPackage/ProductPackage'
import { PartsBoxList } from 'component/Parts'
import Toolbar from 'component/Toolbar/Toolbar'
import { injectIntl } from 'react-intl'
import styles from './Parts.module.css'

class Parts extends React.Component {
  state = {
    partsData: [],
    open: false,
  }

  partsBoxList = (partsType) => {
    return this.props.googleData
      ? this.props.googleData['Parts']['elements'].filter((item) => item['Page'] === partsType)
      : null
  }

  productPackage = (partsType) => {
    return this.props.googleData ? (
      <ProductPackage
        type='parts'
        img={partsType}
        title={partsType + ' Set'}
        googleData={this.props.googleData['IntroPages']['elements']}
      />
    ) : null
  }
  render() {
    const partsType = this.props.match.params ? this.props.match.params.type : 'In-Wall Mount'
    const { intl } = this.props
    // const type = partsTypeContainer.state.partsType
    return (
      // <Subscribe to={[PartsTypeContainer]}>
      //   {(partsTypeContainer) => (
      <div className={styles.Parts} role='presentation'>
        <div className={styles.toolbar_container}>
          <Toolbar
            leftIcon='ic_close'
            title={intl.formatMessage({ id: 'parts' })}
            rightIcon=''
            handleClickBack={this.props.toggleDrawer(this.props.anchor, false)}
          />
        </div>
        {/* <PartsToolbar handleClickBack={this.handleClickBack} /> */}
        <div className={styles.parts_container}>
          {this.productPackage(partsType)}
          <PartsBoxList partsBoxList={this.partsBoxList(partsType)} />
        </div>
      </div>
      // )}
      // </Subscribe>
    )
  }
}

export default injectIntl(Parts)
