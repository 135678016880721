import React from 'react'
import styles from './PartsToolbar.module.css'
import ic_close from 'assets/imgs/ic_close.png'

class PartsToolbar extends React.Component {
  render() {
    return (
      <div className={styles.parts_header}>
        <button onClick={this.props.handleClickBack}>
          <img alt='ic_close' src={ic_close}></img>
        </button>
        <div className={styles.parts_header_title}>Parts</div>
        <div></div>
      </div>
    )
  }
}

export default PartsToolbar
