import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { NavLink } from 'react-router-dom'
import styles from './LogoDialog.module.css'
import logo_awair_business from 'assets/imgs/logo_awair_business.svg'
import { FormattedMessage } from 'react-intl'

export default function NetworkTypeDialog() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div className={styles.dialog_container} onClick={handleClickOpen}>
        <img className={styles.logo} alt='Awair Business' src={logo_awair_business}></img>
      </div>
      <Dialog open={open} onClose={handleClose} className={styles.dialog_pop_container}>
        <div className={styles.dialog_pop_content}>
          <p className={styles.dialog_pop_title}>
            <FormattedMessage id='go_back_to_home_page' />
          </p>
          <p className={styles.dialog_pop_desc}>
            <FormattedMessage id='would_you_like_to_go_back_to_the_home_page' />
          </p>
        </div>
        <div className={styles.dialog_pop_button_container}>
          <p className={styles.dialog_pop_close} onClick={handleClose}>
            <FormattedMessage id='cancel' />
          </p>
          <NavLink className={styles.dialog_pop_close} exact to={'/intro' + window.location.search}>
            <FormattedMessage id='okay' />
          </NavLink>
        </div>
      </Dialog>
    </div>
  )
}
