import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import Toolbar from 'component/Toolbar'
import styles from './Menu.module.css'
import classNames from 'classnames/bind'
import { injectIntl } from 'react-intl'
let cx = classNames.bind(styles)

class Menu extends Component {
  handleClickBack = () => {
    this.props.toggleDrawer(this.props.anchor, false)
  }
  isCurrentPage = (menu) => {
    const partsType = this.props.match.params.type
    const currentPage = '/page/' + encodeURI(partsType) + '/' + menu['Index']
    return window.location.pathname === currentPage
  }
  listText = (menu) => {
    const isMain = menu['Index'].indexOf('-') === -1
    let language = localStorage.getItem('locale')
    const title = language === 'ko' ? menu['Title ko'] : menu['Title']
    return (
      <div
        className={cx({
          list_item_text: true,
          list_item_text_sub: !isMain,
          list_item_text_selected: this.isCurrentPage(menu),
        })}>
        {title}
      </div>
    )
  }
  listIndex = (menu) => {
    const isMain = menu['Index'].indexOf('-') === -1
    const indexString = isMain ? menu['Index'] : menu['Index'].split('-')[1]
    return (
      <div>
        <div
          className={cx({
            list_index: true,
            list_index_main: isMain,
            list_index_sub: !isMain,
            list_index_selected: this.isCurrentPage(menu),
          })}>
          {indexString}
        </div>
      </div>
    )
  }
  render() {
    let arrayMenu = [
      {
        SKU: 'In-Wall Mount',
        Depth: '1',
        Step: '1',
        Index: '1',
        'Card Type': 'card',
        Title: 'Where to Place Your Omni\n',
        Description:
          'Ensure that your desired mounting location is in proximity to a power source. Devices should be mounted 3 to 6 feet (90cm to 1.8m) high and at least 16 feet (5m) away from operable windows, air filters, and fresh air diffusers.',
        'Image (Original)': 'https://drive.google.com/open?id=1uE1pBOBrzDLuUd9vn01okASPbqO2qNsP',
        Next: '2',
        'Choice1 Next': '',
        'Choice2 Next': '',
        'Choice1 Name': '',
        'Choice2 Name': '',
        'Choice1 Image (Original)': '',
        'Choice2 Image (Original)': '',
        Image: 'https://drive.google.com/uc?export=view&id=1uE1pBOBrzDLuUd9vn01okASPbqO2qNsP',
        'Choice1 Image': '',
        'Choice2 Image': '',
        'Pop Title': '',
        'Pop Image (Original)': '',
        'Pop Image': '',
        'Pop Description': '',
      },
    ]

    const partsType = this.props.match.params.type
    if (this.props.googleData) {
      const elements = this.props.googleData[process.env.REACT_APP_SHEET]['elements']
      arrayMenu = elements.filter((item) => item['SKU'] === partsType && item['Index'].split('-').length < 3)
    }
    const { intl } = this.props
    return (
      <div className={styles.full_list} role='presentation'>
        <div className={styles.toolbar_container}>
          <Toolbar
            leftIcon='ic_close'
            title={intl.formatMessage({ id: 'contents' })}
            rightIcon=''
            handleClickBack={this.props.toggleDrawer(this.props.anchor, false)}
          />
        </div>
        <div className={styles.menu_container}>
          <List component='nav' aria-labelledby='nested-list-subheader'>
            {arrayMenu.map((menu, index) => {
              let page = '/page/' + partsType + '/' + menu['Index']
              return (
                <Link key={page} className={styles.list_item_text} to={page + window.location.search}>
                  <ListItem
                    className={cx({
                      list_item: true,
                      list_item_selected: window.location.pathname === page,
                    })}
                    button
                    onClick={this.props.toggleDrawer(this.props.anchor, false)}>
                    {this.listIndex(menu)}
                    {this.listText(menu)}
                  </ListItem>
                </Link>
              )
            })}
          </List>
        </div>
      </div>
    )
  }
}

export default injectIntl(Menu)
