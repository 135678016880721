import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styles from './NetworkTypeBoxDialog.module.css'
import ic_lora from 'assets/imgs/ic_lora.png'
import ic_next_black from 'assets/imgs/ic_next_black.png'

export default function NetworkTypeDialog() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div className={styles.networktype_box} onClick={handleClickOpen}>
        <div className={styles.networktype_box_item}>
          <img className={styles.networktype_box_item_img} alt='LoRa Network' src={ic_lora} />
          <div className={styles.networktype_box_text}>LoRa Network</div>
        </div>
        <img className={styles.networktype_box_next} alt='next' src={ic_next_black} />
      </div>
      <Dialog open={open} onClose={handleClose} className={styles.dialog_pop_container}>
        <div className={styles.dialog_pop_content}>
          <p className={styles.dialog_pop_title}>We are preparing a guide.</p>
          <p className={styles.dialog_pop_desc}>
            Installation Guide not yet available.
            <br />
            We are working to provide you an installation guide. In the meanwhile, please refer to our product manuals.
          </p>
        </div>
        <div className={styles.dialog_pop_button_container}>
          <p className={styles.dialog_pop_close} onClick={handleClose}>
            Close
          </p>
          <a className={styles.dialog_pop_close} href='https://manual.awair.is/'>
            Go to Manuals
          </a>
        </div>
      </Dialog>
    </div>
  )
}
