import React from 'react'
import styles from './Intro.module.css'
import img_mount_install from 'assets/imgs/img-mount-install.png'
import ic_app_download from 'assets/imgs/ic_app_download.png'
import ic_manual from 'assets/imgs/ic_manual.png'
import logo_awair_business from 'assets/imgs/logo_awair_business.svg'
import IntroImage from 'component/IntroImage/IntroImage'
import AppDownDialog from 'component/AppDownDialog/AppDownDialog'
import { getOS } from 'lib/functions'
import { injectIntl, FormattedMessage } from 'react-intl'

class Intro extends React.Component {
  makeAppDownButton() {
    const OS = getOS(window.navigator.userAgent)
    const button = (
      <div className={styles.intro_link_button}>
        <p className={styles.intro_link_button_text}>
          <FormattedMessage id='app_download' />
        </p>
      </div>
    )
    if (OS === 'Android') {
      return <a href='https://play.google.com/store/apps/details?id=co.awair.b2b'>{button}</a>
    } else if (OS === 'iOS') {
      return <a href='https://apps.apple.com/us/app/awair-business/id1495610433'>{button}</a>
    } else {
      return <AppDownDialog type='intro' />
    }
  }

  render() {
    const { intl } = this.props
    return (
      <div className={styles.intro_body}>
        <div className={styles.intro_container}>
          <div>
            <img className={styles.logo} alt='Awair Business' src={logo_awair_business}></img>
            <div className={styles.title}>
              <FormattedMessage id='installation_guide' />
            </div>
          </div>
          <IntroImage
            img={img_mount_install}
            btn={intl.formatMessage({ id: 'start_guide' })}
            link='/networktype'
            info=''
          />
        </div>
        <div>
          <div className={styles.intro_link_container}>
            <img className={styles.intro_link_img} alt='' src={ic_app_download}></img>
            <div className={styles.intro_link_desc_container}>
              <p className={styles.intro_link_title}>
                <FormattedMessage id='app_down_1' />
                <br />
                <FormattedMessage id='app_down_2' />
              </p>
              {this.makeAppDownButton()}
            </div>
          </div>
          <div className={styles.intro_link_container}>
            <img className={styles.intro_link_img} alt='' src={ic_manual}></img>
            <div className={styles.intro_link_desc_container}>
              <p className={styles.intro_link_title}>
                <FormattedMessage id='manual_1' />
                <br />
                <FormattedMessage id='manual_2' />
              </p>
              <a href='https://manual.awair.is/'>
                <div className={styles.intro_link_button}>
                  <p className={styles.intro_link_button_text}>
                    <FormattedMessage id='read_manuals' />
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.intro_copyright_container}>
            <p className={styles.intro_copyright}>Copyright © 2020 Awair. All right reserved.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Intro)
