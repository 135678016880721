import React from 'react'
import styles from './NeedBoxList.module.css'
import NeedBox from 'component/NeedBox/NeedBox'
import { FormattedMessage } from 'react-intl'

class NeedBoxList extends React.Component {
  render() {
    const boxList = this.props.needBoxList.map((needBox) => (
      <NeedBox key={needBox.title} img={needBox.img} title={needBox.title} desc={needBox.desc} />
    ))
    return (
      <div className={styles.need_box_list_container}>
        <div className={styles.need_box_list_title}>
          <FormattedMessage id='what_you_will_need' />
        </div>
        <div className={styles.need_box_list}>{boxList}</div>
      </div>
    )
  }
}

export default NeedBoxList
