import ic_cross from '../../assets/imgs/ic_cross.png';
import ic_flathead from '../../assets/imgs/ic_flathead.png';
import ic_hex from '../../assets/imgs/ic_hex.png';
import ic_pwdrill from '../../assets/imgs/ic_pwdrill.png'
import ic_saw from '../../assets/imgs/ic_saw.png';
import ic_poe_injector from '../../assets/imgs/ic_poe_injector.png';
import ic_laptop from '../../assets/imgs/ic_laptop.png';

export default [
  {
    type: 'In-Wall Mount',
    img: ic_cross,
    title: 'Cross Screwdriver',
    desc: '',
  },
  {
    type: 'In-Wall Mount',
    img: ic_flathead,
    title: '2.5mm Flathead Screwdriver',
    desc: 'For rotating the switch on Surface Mount',
  },
  {
    type: 'In-Wall Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'In-Wall Mount',
    img: ic_pwdrill,
    title: 'Power Drill',
    desc: 'Drywall Only',
  },
  {
    type: 'In-Wall Mount',
    img: ic_saw,
    title: 'Concrete Saw',
    desc: 'Drywall Only',
  },
  {
    type: 'Surface Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'Secure Mount',
    img: ic_cross,
    title: 'Cross Screwdriver',
    desc: '',
  },
  {
    type: 'Secure Mount',
    img: ic_flathead,
    title: '2.5mm Flathead Screwdriver',
    desc: 'For rotating the switch on Surface Mount',
  },
  {
    type: 'Secure Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'Secure Mount',
    img: ic_pwdrill,
    title: 'Power Drill',
    desc: 'Drywall Only',
  },
  {
    type: 'Secure Mount',
    img: ic_saw,
    title: 'Concrete Saw',
    desc: 'Drywall Only',
  },
  {
    type: 'BACnet Mount',
    img: ic_cross,
    title: 'Cross Screwdriver',
    desc: '1mm and 2mm (Power Drill Size)',
  },
  {
    type: 'BACnet Mount',
    img: ic_flathead,
    title: '2.5mm Flathead Screwdriver',
    desc: 'For rotating the switch on Surface Mount',
  },
  {
    type: 'BACnet Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'BACnet Mount',
    img: ic_pwdrill,
    title: 'Power Drill',
    desc: 'Drywall Only',
  },
  {
    type: 'BACnet Mount',
    img: ic_saw,
    title: 'Concrete Saw',
    desc: 'Drywall Only',
  },
  {
    type: 'BACnet Mount',
    img: ic_poe_injector,
    title: 'POE Injector',
    desc: 'For power and configuration (Recommended)',
  },
  {
    type: 'BACnet Mount',
    img: ic_laptop,
    title: 'Laptop',
    desc: 'For configuration',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_cross,
    title: 'Cross Screwdriver',
    desc: '1mm and 2mm (Power Drill Size)',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_flathead,
    title: '2.5mm Flathead Screwdriver',
    desc: 'For rotating the switch on Surface Mount',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_pwdrill,
    title: 'Power Drill',
    desc: 'Drywall Only',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_saw,
    title: 'Concrete Saw',
    desc: 'Drywall Only',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_poe_injector,
    title: 'POE Injector',
    desc: 'For power and configuration (Recommended)',
  },
  {
    type: 'BACnet IP Mount',
    img: ic_laptop,
    title: 'Laptop',
    desc: 'For configuration',
  },
  {
    type: 'Ethernet Mount',
    img: ic_cross,
    title: 'Cross Screwdriver',
    desc: '1mm and 2mm (Power Drill Size)',
  },
  {
    type: 'Ethernet Mount',
    img: ic_flathead,
    title: '2.5mm Flathead Screwdriver',
    desc: 'For rotating the switch on Surface Mount',
  },
  {
    type: 'Ethernet Mount',
    img: ic_hex,
    title: '2mm Hex Screwdriver',
    desc: 'Power Drill Size: 2mm',
  },
  {
    type: 'Ethernet Mount',
    img: ic_pwdrill,
    title: 'Power Drill',
    desc: 'Drywall Only',
  },
  {
    type: 'Ethernet Mount',
    img: ic_saw,
    title: 'Concrete Saw',
    desc: 'Drywall Only',
  },
]
